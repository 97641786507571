import React from 'react';
import CompletarRegistroForm from '../components/RegistroUsuario/CompletarRegistroForm';

const CompletarRegistro = () => {
  return (
    <div className='template_completeRegister vh-100 vw-100 d-flex justify-content-center align-items-center p-3 '>
      <CompletarRegistroForm />
    </div>
  );
};

export default CompletarRegistro;
