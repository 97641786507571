import React, { Fragment, useEffect } from "react";
import { formatToTwoDecimals } from "../../services/data";
const Nodes = "/images/dashboard/nodos.svg";
const Database = "/images/dashboard/dashboard_db.png";
const TimeAverage = "/images/dashboard/time_average.svg";
const Arrowws = "/images/dashboard/cargas.svg";
const Transactions = "/images/dashboard/dashboard_transactions.png";
const QualityRegister = "/images/dashboard/quality.svg";
const PictureQuality = "/images/dashboard/picture_quality.svg";

const StatisticsConsorcio = ({ statisticsConsorcioData }) => {
  return (
    <Fragment key="statistics">
      <div
        style={{ gap: "10px", marginTop: "23.86px", flexFlow: "wrap" }}
        className="container-fluid d-flex px-3"
      >
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div className="d-flex align-items-end" style={{ gap: "5px" }}>
            <img
              style={{ marginBottom: "0px" }}
              src={Nodes}
              alt="Nodes icon"
              width={"28px"}
              height={"20px"}
            />
            <p
              className="main_title"
              style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
            >
              {statisticsConsorcioData?.estatus_nodos?.activos ?? 0}/
              {statisticsConsorcioData?.estatus_nodos?.total ?? 0}
            </p>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Nodos en el consorcio
          </p>
        </div>
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div
            className="d-flex align-items-end dashboard_petitions_text_container"
            style={{ gap: "54px" }}
          >
            <div className="d-flex align-items-end" style={{ gap: "5px" }}>
              <img
                style={{ marginBottom: "2px" }}
                src={Database}
                alt="Database icon"
                width={"23.66px"}
                height={"28px"}
              />
              <p
                className="main_title"
                style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
              >
                {statisticsConsorcioData?.noRegistros ?? "0"}
              </p>
            </div>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Registros en el consorcio
          </p>
        </div>
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div className="d-flex align-items-end" style={{ gap: "5px" }}>
            <img
              style={{ marginBottom: "0px" }}
              src={Transactions}
              alt="Database icon"
              width={"28px"}
              height={"20px"}
            />
            <p
              className="main_title"
              style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
            >
              {statisticsConsorcioData?.peticionesTransacciones
                ?.peticiones_validacion_identidad ?? "0"}
            </p>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Transacciones en el consorcio
          </p>
        </div>
      </div>
      <div
        style={{ gap: "10px", marginTop: "23.86px", flexFlow: "wrap" }}
        className="container-fluid d-flex px-3"
      >
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div className="d-flex align-items-end" style={{ gap: "5px" }}>
            <img
              style={{ marginBottom: "0px" }}
              src={QualityRegister}
              alt="Database icon"
              width={"28px"}
              height={"28px"}
            />
            <p
              className="main_title"
              style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
            >
              {formatToTwoDecimals(statisticsConsorcioData?.calidadRegistroPromedio ?? 0) ?? "0"}
            </p>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Promedio índice de calidad del registro
          </p>
        </div>
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div
            className="d-flex align-items-end dashboard_petitions_text_container"
            style={{ gap: "54px" }}
          >
            <div className="d-flex align-items-end" style={{ gap: "5px" }}>
              <img
                style={{ marginBottom: "2px" }}
                src={PictureQuality}
                alt="Database icon"
                width={"28px"}
                height={"28px"}
              />
              <p
                className="main_title"
                style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
              >
                {formatToTwoDecimals(statisticsConsorcioData?.calidadFotoPromedioValidacion) ?? "0"}
              </p>
            </div>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Promedio índice de calidad de la fotografía
          </p>
        </div>
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div className="d-flex align-items-end" style={{ gap: "5px" }}>
            <img
              style={{ marginBottom: "0px" }}
              src={Arrowws}
              alt="Database icon"
              width={"32.7px"}
              height={"25.58px"}
            />
            <p
              className="main_title"
              style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
            >
              {statisticsConsorcioData?.no_Cargas ?? "0"}
            </p>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Cargas en el consorcio
          </p>
        </div>
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div className="d-flex align-items-end" style={{ gap: "5px" }}>
            <img
              style={{ marginBottom: "0px" }}
              src={TimeAverage}
              alt="Database icon"
              width={"28px"}
              height={"28px"}
            />
            <p
              className="main_title"
              style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
            >
              {statisticsConsorcioData?.tiempoPromedio ?? "0"}
              <small className="main_title" style={{ fontSize: "16px" }}>
                seg
              </small>
            </p>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Promedio tiempo de respuesta
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default StatisticsConsorcio;
