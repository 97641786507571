import { UserContext } from "./Body";
import Select from "./Select";
import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";
import DeleteUser from "./DeleteUser";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import ActivateUser from "./ActivateUser";
import BlockUser from "./BlockUser";
import { Permisos } from "../../services/permisos";
import { TIME_ALERT } from "../../services/data";

const useUserContext = () => useContext(UserContext);

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

export const handleActivate = async ({
  id,
  loading,
  error,
  error_message,
  success,
  success_message,
  service,
  handleRefreshData,
}) => {
  try {
    loading();
    const response = await service(id);
    if (!response.success) {
      loading();
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    loading();
    success();
    success_message(response.message);
    handleRefreshData();
    setTimeout(() => {
      success();
      success_message("");
    }, TIME_ALERT);
  } catch (err) {
    loading();
    error();
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};
const useUserTableStatus = ({ status, id }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [activateUser, setActivateUser] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [value, setValue] = useState({});
  const [selectStatusOptions, setSelectStatusOptions] = useState([]);
  const deleteButtonRef = useRef(null);
  const BlockButtonRef = useRef(null);

  const { statusOptions, handleRefreshData } = useUserContext();
  const [typeAction, setTypeAction] = useState("");

  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = (msj) => {
    setSuccessMessage(msj);
  };

  const handleActiveUser = () => {
    if (!navigator.onLine) {
      setError(true);
      setErrorMessage(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    setActivateUser((prevState) => !prevState);
  };

  const handleDeleteUser = useCallback(() => {
    if (!navigator.onLine) {
      setError(true);
      setErrorMessage(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    if (deleteButtonRef && deleteButtonRef?.current !== null) {
      setTypeAction("delete");
      deleteButtonRef.current.click();
    }
  }, [deleteButtonRef]);

  const handleBlockUser = useCallback(() => {
    if (!navigator.onLine) {
      setError(true);
      setErrorMessage(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    if (BlockButtonRef && BlockButtonRef?.current !== null) {
      BlockButtonRef.current.click();
    }
  }, [BlockButtonRef]);

  const availableActions = useMemo(
    () => ({
      0: handleDeleteUser,
      1: handleActiveUser,
      2: handleBlockUser,
    }),
    []
  );

  useEffect(() => {
    setValue(statusOptions.filter((value) => value.id === status)[0]?.id ?? "");
  }, []);

  const filterOptionsByStatus = useCallback(
    (userStatus) => {
      return statusOptions.filter((val) => val.id !== userStatus);
    },
    [statusOptions]
  );

  const possibleSelectOptions = useMemo(
    () => ({
      0: () => filterOptionsByStatus(2),
      1: statusOptions,
      2: () => filterOptionsByStatus(0),
    }),
    [filterOptionsByStatus, statusOptions]
  );

  useEffect(() => {
    setSelectStatusOptions(possibleSelectOptions[status]);
  }, [possibleSelectOptions, status]);

  const handleChange = (e) => {
    availableActions[e.value.toString()]();
  };

  const handleCancelChangeStatus = () => {
    setValue(status);
  };

  return {
    value,
    statusOptions,
    handleChange,
    deleteButtonRef,
    BlockButtonRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    activateUser,
    handleActiveUser,
    selectStatusOptions,
    handleCancelChangeStatus,
  };
};

export default function UserStatus({ status, id, name, index, rolesUser }) {
  const {
    value,
    handleCancelChangeStatus,
    handleChange,
    deleteButtonRef,
    BlockButtonRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    activateUser,
    handleActiveUser,
    selectStatusOptions,
  } = useUserTableStatus({
    status,
    id,
  });
  const windowWidth = useWindowWidth();
  return (
    <>
      <Select
        id={`user_table_status_${id}`}
        name={`user_table_status_${id}`}
        label=""
        value={value}
        handleSetValue={handleChange}
        options={
          Permisos.verificarPermiso("Usuario", "Ver la sección")
            ? selectStatusOptions
            : []
        }
        valueKey="id"
        labelKey="name"
        arrow="white"
        max_width={67}
        arrowWidth={5}
        hide_current={true}
        {...(index >= 7 || windowWidth <= 768
          ? {
              direction: {
                bottom: "auto",
                left: "0",
                top: "0",
                transform: "translateY(-101%)",
              },
            }
          : {})}
        arrowPosition={{ right: "5px" }}
        customStyles={{
          color: "white",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0px 12px 0 5px",
          lineHeight: "9px",
          maxHeight: "19px",
          borderRadius: "4px",
          minHeight: "19px",
          display: "flex",
          alignItems: "center",
          background:
            status === 0 ? "#D9D9D9" : status === 1 ? "#609571" : "#C43C42",
          border: `1px solid ${
            status === 0 ? "#D9D9D9" : status === 1 ? "#609571" : "#C43C42"
          } `,
        }}
      />
      {activateUser && (
        <ActivateUser
          id={id}
          handleActiveUser={handleActiveUser}
          status={status}
          rolesActuales={rolesUser}
        />
      )}
      <DeleteUser
        id={id}
        name={name}
        deleteButtonRef={deleteButtonRef}
        cancelAction={handleCancelChangeStatus}
      />
      <BlockUser
        id={id}
        name={name}
        BlockButtonRef={BlockButtonRef}
        cancelAction={handleCancelChangeStatus}
      />
      {/* {Permisos.verificarPermiso("Usuario", "Eliminar") && (
      )} */}
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
