import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import writeXlsxFile from "write-excel-file";
import dayjs from "dayjs";
import csvDownload from "json-to-csv-export";
import { UserContext } from "./Body";
import Checkbox from "../Inputs/Checkbox";
import Modal from "../Modal/Modal";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import { formatDateToGMT06, TIME_ALERT } from "../../services/data";

const useUserContext = () => useContext(UserContext);

const INITIAL_VALUES = {
  excel: "",
  csv: "",
  pdf: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...INITIAL_VALUES,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const useExportToExcel = () => {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { statusOptions, handleDownload: downloadService } = useUserContext();
  const modalRef = useRef(null);

  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
  };
  const schema = [
    {
      column: "Nombre(s)",
      type: String,
      value: ({ user }) => user.nombre,
    },
    {
      column: "Apellido Paterno",
      type: String,
      value: ({ user }) => user.apellidoPaterno,
    },
    {
      column: "Apellido Materno",
      type: String,
      value: ({ user }) => user.apellidoMaterno,
    },
    {
      column: "Correo",
      type: String,
      value: ({ user }) => user.email,
    },
    {
      column: "Puesto",
      type: String,
      value: ({ user }) => user.puesto,
    },
    {
      column: "Roles",
      type: String,
      value: (userData) => userData?.roles[0]?.name ?? "",
    },
    {
      column: "Fecha de alta",
      type: String,
      value: ({ user }) =>
        dayjs(formatDateToGMT06(user.fechaAlta))
          .format("YYYY-MM-DD")
          .toString(),
      sortable: false,
    },
    {
      column: "Status",
      type: String,
      value: ({ user }) =>
        statusOptions.find((opt) => opt.id === user.status)?.name,
    },
  ];

  const pdfDownload = async () => {
    await downloadService("pdf");
  };

  const excelDownload = async () => {
    await downloadService("xlsx")
  }

  const csvDownloader = async () => {
    await downloadService("csv")
  };

  const availableDownloads = {
    excel: excelDownload,
    csv: csvDownloader,
    pdf: pdfDownload,
  };

  const handleDownload = async (type) => {
    try {
      setLoading(true);
      await availableDownloads[type]();
      setSuccess(true);
      setSuccessMessage("La exportación de datos se ha realizado con éxito");
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
        handleModal();
      }, TIME_ALERT);
    } catch (error) {
      if (error.message === "LIMITE") {
        setErrorMessage("Limite de registros excedido");
        setError(true);
      } else if (error.message === "") {
      } else {
        setErrorMessage("Ocurrió un error al exportar");
        setError(true);
      }

      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!navigator.onLine) {
      setError(true);
      setErrorMessage(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    const selected = Object.entries(values).reduce((acc, [key, value]) => {
      if (value === "true") {
        return key;
      }
      return acc;
    }, null);
    if (!selected) {
      setError(true);
      setErrorMessage("Por favor seleccione un formato de exportación");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    handleDownload(selected);
  };
  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    setFieldValue(name, "true");
  };

  useEffect(() => {
    setIsValid(
      Object.entries(values).reduce((acc, [key, value]) => {
        if (value === "true") {
          return key;
        }
        return acc;
      }, null)
    );
  }, [values]);

  return {
    isValid,
    values,
    modalRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    handleDownload,
    handleCheckbox,
    handleSubmit,
    handleModal,
  };
};

export default function ExportData() {
  const {
    isValid,
    values,
    modalRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    handleCheckbox,
    handleSubmit,
    handleModal,
  } = useExportToExcel();
  return (
    <>
      <Modal ref={modalRef}>
        <div
          className={
            "user_export_data_container bg-white rounded position-absolute top-50 start-50 translate-middle modal_export_users"
          }
        >
          <div class="container-fluid d-flex flex-column p-0 justify-content-start align-items-start modal_container_title">
            <h4 class="main_title">Usuarios</h4>
            <p class="main_subtitle">
              Usuarios Ve el listado de usuarios, agrega o edita usuarios
            </p>
          </div>
          <div className="export_shadow_card container-fluid d-flex justify-content-center align-items-start p-0 flex-column">
            <p className="card_title" style={{ marginBottom: "25px" }}>
              Exportación de información
            </p>
            <p className="card_text">
              Se exportará la información seleccionada en los siguientes
              formatos:
            </p>
            <form onSubmit={handleSubmit} className="container p-0">
              <div className="container-fluid p-0 d-flex gap-3">
                <div className="">
                  <Checkbox
                    id="excel"
                    name="excel"
                    label="XLS"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.excel}
                  />
                </div>
                <div className="">
                  <Checkbox
                    id="csv"
                    name="csv"
                    label="CSV"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.csv}
                  />
                </div>
                <div className="">
                  <Checkbox
                    id="pdf"
                    name="pdf"
                    label="PDF"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.pdf}
                  />
                </div>
              </div>
              <div
                style={{ marginTop: "30px" }}
                className={"container-fluid p-0 d-flex gap-3"}
              >
                <button className={`button button_active`} type="submit">
                  Exportar
                </button>
                <button
                  type="button"
                  onClick={handleModal}
                  className="cancel_button"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <button
        type="button"
        onClick={handleModal}
        className="export_button d-flex gap-1 justify-content-center align-items-center"
      >
        Exportar
      </button>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
