import React from "react";
import DatePickerInput from "../Inputs/DatePickerInput";
import ClockIcon from "./ClockIcon";
import TimeInput from "../Inputs/TimeInput";
import dayjs from "dayjs";
import Select from "../Inputs/Select";

import {
  daysOptions,
  getMaxStartHour,
  getMinEndHour,
  getMaxEndHour,
} from "../../services/data";

export const useTimeFilters = ({
  handleDatePickerChange,
  handleChange,
  values,
}) => [
  <div
    key="filter1"
    style={{ width: "fit-content" }}
    className="container-fluid p-0 m-0"
    data-testid="desde-filter-date"
  >
    <DatePickerInput
      onChange={handleDatePickerChange}
      value={values.start_date}
      label="Desde"
      id="start_date"
      valid={true}
      rounded={true}
      minDate={dayjs().subtract(90, "day")}
      maxDate={dayjs(values.end_date)}
    />
  </div>,
  <div
    key="filter2"
    style={{ width: "fit-content" }}
    className="container-fluid p-0 m-0"
    data-testid="desde-filter-hour"
  >
    <TimeInput
      value={values.start_hours}
      rounded={true}
      arrowWidth={5}
      id={`start_hours`}
      name={`start_hours`}
      label=""
      handleSetValue={({ field, value }) => {
        handleChange({
          name: field,
          value,
        });
      }}
      icon={<ClockIcon />}
      maxTime={getMaxStartHour({
        start_date: values.start_date,
        start_hours: values.start_hours,
        end_date: values.end_date,
        end_hours: values.end_hours,
      })}
    />
  </div>,
  <div
    key="filter3"
    style={{ width: "fit-content" }}
    className="container-fluid p-0 m-0"
    data-testid="hasta-filter-date"
  >
    <DatePickerInput
      onChange={handleDatePickerChange}
      value={values.end_date}
      label="Hasta"
      id="end_date"
      valid={true}
      rounded={true}
      minDate={dayjs(values.start_date)}
      maxDate={dayjs()}
    />
  </div>,
  <div
    key="filter4"
    style={{ width: "fit-content" }}
    className="container-fluid p-0 m-0"
    data-testid="hasta-filter-hour"
  >
    <TimeInput
      value={values.end_hours}
      rounded={true}
      arrowWidth={5}
      id={`end_hours`}
      name={`end_hours`}
      label=""
      handleSetValue={({ field, value }) => {
        handleChange({
          name: field,
          value,
        });
      }}
      minTime={getMinEndHour({
        start_date: values.start_date,
        start_hours: values.start_hours,
        end_date: values.end_date,
        end_hours: values.end_hours,
      })}
      maxTime={getMaxEndHour({
        end_date: values.end_date,
        end_hours: values.end_hours,
      })}
      icon={<ClockIcon />}
    />
  </div>,
  <div
    key="filter5"
    style={{ width: "fit-content" }}
    className="container-fluid p-0 m-0 "
    data-testid="filtro-lapsos"
  >
    <Select
      id={`days`}
      name={`days`}
      label=""
      value={values.days}
      handleSetValue={({ field, value }) => {
        handleChange({
          name: field,
          value,
        });
      }}
      options={daysOptions}
      valueKey="value"
      labelKey="label"
      rounded={true}
      max_width={52}
      arrowWidth={5}
    />
  </div>,
];
