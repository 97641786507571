import React, { useContext, useMemo } from "react";
import SearchInput from "../Inputs/SearchInput";
import Select from "../Inputs/Select";
import { NodeContext } from "./NodosComponent";
import SlideWrapper from "../Sharing/SlideWrapper";

const useNodeContext = () => useContext(NodeContext);

export default function FilterForm({
  handleChange = () => {},
  handleSelectChange = () => {},
  values,
  handleRangeDateChange = (val) => {
    console.log(val);
  },
}) {
  const { nodeCatalog } = useNodeContext();

  const memoNodeCatalog = useMemo(
    () => [
      {
        nodo_id: "",
        nodo_name: "",
        nodo_label: "Todos los nodos",
      },
      ...nodeCatalog.map(({ nodo_id, nodo_name }) => ({
        nodo_id,
        nodo_name,
        nodo_label: nodo_name,
      })),
    ],
    [nodeCatalog]
  );

  return (
    <form
      className="container-fluid p-0 justify-content-start d-flex gap-1 gestion_filter_form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <SlideWrapper values={values}>
        <div style={{ maxWidth: "137px" }} className="container-fluid p-0 m-0">
          <SearchInput
            onChange={handleChange}
            value={values.search}
            label="Buscar"
            id="search"
            name="search"
            type="search"
            valid={true}
            rounded={true}
          />
        </div>
        {/*<div className="container-fluid p-0 m-0">
          <Select
            id={`node`}
            name={`node`}
            label=""
            value={values?.node ?? ""}
            handleSetValue={({ field, value }) => {
              handleSelectChange({
                name: field,
                value,
              });
            }}
            options={memoNodeCatalog}
            valueKey="nodo_name"
            labelKey="nodo_label"
            rounded={true}
            arrowWidth={5}
          />
        </div>*/}
      </SlideWrapper>

      <button type="submit" className="d-none"></button>
    </form>
  );
}
