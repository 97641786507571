import React, { useState, useEffect } from "react";
import Header from "./Header";
import Rows from "./Rows";

const Container = React.memo(
  ({
    columns,
    data,
    row_classname,
    row_styles,
    header_styles,
    header_classname,
    children,
    setSortConfig,
    sortConfig,
  }) => {
    const handleSort = (key) => {
      setSortConfig({
        key,
        direction: sortConfig.direction === "asc" ? "desc" : "asc",
      });
    };

    return (
      <div className="table-responsive shadow rounded">
        <table className="table mb-0">
          <Header
            sortConfig={sortConfig}
            columns={columns}
            handleSort={handleSort}
            {...(header_styles && { header_styles: header_styles })}
            {...(header_classname && { header_classname: header_classname })}
          />
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colspan="10" className="table_row_cell_no_content">
                  <div
                    style={{ padding: "30px" }}
                    className="container d-flex justify-content-center"
                  >
                    <p style={{ fontSize: "16px", opacity: ".8" }}>
                      No hay contenido que mostrar en esta tabla
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              data.map((row, index) => (
                <Rows
                  key={`table-row-${index}`}
                  columns={columns}
                  row={row}
                  index={index}
                  {...(row_classname && { row_classname: row_classname })}
                  {...(row_styles && { row_styles: row_styles })}
                />
              ))
            )}
          </tbody>
        </table>
        {children}
      </div>
    );
  }
);

export default Container;
