import React, { useContext, useMemo } from "react";
import SearchInput from "../Inputs/SearchInput";
import { TransaccionContext } from "./Body";
import Select from "../Inputs/Select";
import ScoreInput from "../Inputs/ScoreInput";
import SlideWrapper from "../Sharing/SlideWrapper";
import { useTimeFilters } from "../Sharing/TimeFilters";

const useTransactionsContext = () => useContext(TransaccionContext);

const coincidenceOptions = [
  {
    label: "N/A",
    value: "N/A",
  },
  {
    label: "Coincidencia",
    value: "Coincidencia",
  },
  {
    label: "Sin Coincidencia",
    value: "Sin Coincidencia",
  },
  {
    label: "No existe",
    value: "No existe",
  },
];

export default function FilterForm({
  handleChange,
  handleDatePickerChange,
  values,
  responseTimeLimits,
}) {
  const { nodeCatalog } = useTransactionsContext();
  const timeFilters = useTimeFilters({
    handleDatePickerChange,
    handleChange,
    values,
  });

  const memoNodeCatalog = useMemo(
    () => [
      {
        nodo_id: "",
        nodo_name: "",
        nodo_label: "Todos los nodos",
      },
      ...nodeCatalog.map(({ nodo_id, nodo_name }) => ({
        nodo_id,
        nodo_name,
        nodo_label: nodo_name,
      })),
    ],
    [nodeCatalog]
  );

  return (
    <form
      className="container-fluid p-0 justify-content-start d-flex gap-1 transactrions_filter_form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{ marginRight: "10px" }}
    >
      <SlideWrapper values={values}>
        <div style={{ maxWidth: "117px" }} className="container-fluid p-0 m-0">
          <SearchInput
            onChange={(e) => {
              handleChange({
                name: e.target.name,
                value: e.target.value,
              });
            }}
            value={values.search}
            label="Buscar"
            id="search"
            name="search"
            type="search"
            valid={true}
            rounded={true}
          />
        </div>
        {/* <div
          style={{ maxWidth: "108px" }}
          className="container-fluid p-0 m-0"
          data-testid="filtro-nodos"
        >
          <Select
            id={`all_nodes`}
            name={`all_nodes`}
            label=""
            value={values.all_nodes}
            handleSetValue={({ field, value }) => {
              handleChange({
                name: field,
                value,
              });
            }}
            options={memoNodeCatalog}
            valueKey="nodo_name"
            labelKey="nodo_label"
            arrowWidth={5}
            rounded={true}
          />
        </div>*/}
        {timeFilters}
        <div
          className="container-fluid p-0 m-0"
          data-testid="filtro-validacion"
        >
          <Select
            id={`all`}
            name={`all`}
            label="Todas"
            value={values.all}
            handleSetValue={({ field, value }) => {
              handleChange({
                name: field,
                value,
              });
            }}
            options={coincidenceOptions}
            valueKey="value"
            labelKey="label"
            arrowWidth={5}
            rounded={true}
          />
        </div>
        <div
          style={{ maxWidth: "140px" }}
          className="container-fluid p-0 m-0 "
          data-testid="filtro-container-tiempo-respuesta"
        >
          <ScoreInput
            id={`response_time`}
            name={`response_time`}
            label="Tiempo de respuesta"
            value={values.response_time}
            handleSetValue={({ field, value }) => {
              handleChange({
                name: field,
                value,
              });
            }}
            arrowWidth={5}
            max_width={140}
            rounded={true}
            {...responseTimeLimits}
          />
        </div>
      </SlideWrapper>
      <button type="submit" className="d-none"></button>
    </form>
  );
}
