import { useEffect, useState } from "react";
import * as bootstrap from "bootstrap";
export default function RolUser({ roles }) {
  const [divTooltips, setDivTooltips] = useState(false);
  useEffect(() => {
    const roleNames = roles.map((role) => role.name).join(", "); // Unir los nombres de roles en un string
    setDivTooltips(
      <div
        title={"<div class='tooltip_table_rol'>" + roleNames + "</div>"}
        data-bs-toggle="tooltip"
        data-bs-placement="left"
      >
        {roles.length > 1
          ? roles[0]?.name
            ? `${roles[0].name}, +`
            : ""
          : roles[0]?.name ?? ""}
      </div>
    );
  }, [roles]);
  useEffect(() => {
    let tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, { html: true });
    });
  }, [divTooltips]);

  return divTooltips;
}
