import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { deleteUserById } from "../../services/api";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { UserContext } from "./Body";
import ChangeUserStatusConfirmation from "./ChangeUserStatusConfirmation";
import { TIME_ALERT } from "../../services/data";

const useUserContext = () => useContext(UserContext);

export const handleDelete = async ({
  id,
  loading,
  error,
  error_message,
  success,
  success_message,
  service,
  handleFinished,
}) => {
  try {
    loading();
    const response = await service(id);
    if (!response.success) {
      loading();
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    loading();
    success();
    success_message(response.message);
    handleFinished();
    setTimeout(() => {
      success();
      success_message("");
    }, TIME_ALERT);
  } catch (err) {
    loading();
    error();
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};

const useDeleteUsers = ({ id }) => {
  const [idUser, setIdUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [finished, setFinished] = useState(false);
  const { handleRefreshData } = useUserContext();

  const handleFinished = () => {
    setFinished((prevState) => !prevState);
  };
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };
  const handleError = () => {
    setError((prevState) => !prevState);
  };
  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };
  const handleSuccessMessage = (msj) => {
    setSuccessMessage(msj);
  };

  const deleteUser = async () => {
    await handleDelete({
      id: idUser,
      service: deleteUserById,
      loading: () => handleLoading(),
      error: () => handleError(),
      error_message: (msj) => handleErrorMessage(msj),
      success: () => handleSuccess(),
      success_message: (msj) => handleSuccessMessage(msj),
      handleFinished,
    });
  };

  useEffect(() => {
    setIdUser(id);
  }, [id]);

  return {
    setFinished,
    finished,
    deleteUser,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    handleRefreshData,
  };
};

export default function DeleteUser({
  id,
  name,
  deleteButtonRef,
  cancelAction,
}) {
  const {
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    deleteUser,
    ...modalProps
  } = useDeleteUsers({ id });

  return (
    <>
      <ChangeUserStatusConfirmation
        {...modalProps}
        actionButtonRef={deleteButtonRef}
        name={name}
        title={"Confirma la baja del usuario"}
        subtitle={
          "Al confirmar, el usuario se dará de baja de la plataforma y no podrá acceder a su cuenta"
        }
        actionFunction={deleteUser}
        cancelFunction={cancelAction}
        finishedTitle={"Confirma la baja del usuario"}
        finishedSubtitle={"El usuario se ha dado de baja correctamente"}
      />
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {/*success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )*/}
    </>
  );
}
