import React, { useRef, useState } from "react";
import EditForm from "./EditForm";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Modal from "../Modal/Modal";
import { TIME_ALERT } from "../../services/data";

export default function EditUser({
  id,
  type = "edit",
  button_styles,
  status,
  children,
}) {
  const [showAlert, setShowAlert] = useState(false);
  const modalRef = useRef(null);
  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
  };

  const handleAlert = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, TIME_ALERT);
  };

  return (
    <>
      {showAlert && (
        <AlertaGeneral AlertaGeneral type={"error"}>
          No es posible editar un usuario Inactivo
        </AlertaGeneral>
      )}
      {status !== 0 && (
        <Modal ref={modalRef}>
          <EditForm
            type={type}
            idUser={id}
            modalRef={modalRef}
            handleModal={handleModal}
          />
        </Modal>
      )}
      <button
        className={`${button_styles ? button_styles : "edit_user_button"}`}
        type="button"
        onClick={status !== 0 ? handleModal : handleAlert}
      >
        {children}
      </button>
    </>
  );
}
