import React, {
  useState,
  useReducer,
  useEffect,
  useMemo,
  createContext,
  useCallback,
  useRef,
} from "react";
import FilterForm from "./FilterForm";
import Table from "../Table/Container";
import TableActions from "./TableActions";
import UserStatus from "./UserStatus";
import UserProfilePhoto from "./UserProfilePhoto";
import { getAllUsersService } from "../../services/api";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import CreateUser from "./CreateUser";
import {
  handleGetCatalogs,
  TIME_ALERT,
  toUserTimeZone,
} from "../../services/data";
import { getCatalogs, exportUsersService } from "../../services/api";
import ExportData from "./ExportData";
import CreateRol from "./CreateRol";
import { Permisos } from "../../services/permisos";
import { formatDateToGMT06 } from "../../services/data.js";
import usePaginationBack from "../Table/usePaginationBack";
import Pagination from "../Table/Pagination";
import { endpoints, loadEndpoints } from "../../services/api";
import LimpiarFiltros from "../Sharing/LimpiarFiltros.jsx";
import RolUser from "./RolUser.jsx";

export const UserContext = createContext();

export const handleExportUsers = async ({
  values,
  service,
  loading,
  error,
  error_message,
  type,
}) => {
  try {
    loading();
    const response = await service({ ...values, type });
    if (!response.success) {
      loading();
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    loading();
    return response.data;
  } catch (err) {
    loading();
    error();
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};
const get_INITIAL_VALUES = () => {
  return {
    search: "",
    status: "",
    register_date: "",
    rol: "",
  };
};
const INITIAL_VALUES = get_INITIAL_VALUES();

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET_FIELDS":
      return {
        ...action.value,
      };
    default:
      return state;
  }
};

const mappingOrderKeys = {
  nombre: "Nombre",
  apellidoPaterno: "ApellidoPaterno",
  apellidoMaterno: "ApellidoMaterno",
  email: "Correo",
  fechaAlta: "FechaAlta",
  status: "Estatus",
  puesto: "Puesto",
  roles: "Roles",
};

export const handleGetAllUsers = async ({
  values,
  service,
  loading,
  error,
  errorVal,
  error_message,
  handleValidateTotalPages,
  setTableData,
  signal,
}) => {
  try {
    if (signal.current) {
      signal.current.abort();
    }
    signal.current = new AbortController();
    loading(true);
    const response = await service({
      ...values,
      signal: signal.current.signal,
    });
    if (!response.success) {
      if (response.name === "AbortError") {
        console.log("Request canceled");
      } else {
        loading(false);
        if (!errorVal) {
          error(true);
          error_message(response.message);
          setTimeout(() => {
            error(false);
            error_message("");
          }, TIME_ALERT);
        }
      }
      console.error("Error request");
      return;
    }
    loading(false);
    handleValidateTotalPages({
      totalPages: response.pagination.TotalPage,
      page: response.pagination.CurrentPage,
      totalElements: response.pagination.TotalCount,
    });
    setTableData(response.data);
  } catch (err) {
    loading(false);
    error(true);
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error(false);
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};

export default function Body() {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [refreshRol, setRefreshRol] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [rolOptions, setRolOptions] = useState([]);
  const dataTable = useMemo(() => tableData, [tableData]);
  const [orderConfig, setOrderConfig] = useState({
    direction: "asc",
    key: "nombre",
  });
  const abortControllerRef = useRef(null);
  const num_limite_registros_export = useRef(100);

  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();
  // Handlers
  const resetFields = () => {
    dispatch({ type: "RESET_FIELDS", value: INITIAL_VALUES });
  };

  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };

  const getUsers = async () => {
    handleGetAllUsers({
      values: {
        current_page: pagination.page,
        limit: true,
        OrderAscending: orderConfig.direction,
        OrderBy: mappingOrderKeys[orderConfig.key],
        ...values,
      },
      loading: setLoading,
      error: setError,
      errorVal: error,
      error_message: (msj) => handleErrorMessage(msj),
      handleValidateTotalPages,
      setTableData,
      service: getAllUsersService,
      signal: abortControllerRef,
    });
  };

  const handleRefreshData = () => {
    setRefreshData((prevState) => !prevState);
  };

  const handleRefreshRol = () => {
    setRefreshRol((prevState) => !prevState);
  };

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleChange = (e) => {
    handleResetPagination();
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleSelectChange = (values) => {
    handleResetPagination();
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleDatePickerChange = (newValue) => {
    handleResetPagination();
    const { name, value } = newValue;
    const valueSet =
      dayjs(value).format("YYYY-MM-DD") !== "Invalid Date"
        ? dayjs(value).format("YYYY-MM-DD")
        : "";
    setFieldValue(name, valueSet);
  };

  const handleExport = async () => {
    if (pagination?.totalElements > num_limite_registros_export?.current) {
      throw new Error("LIMITE");
    }
    const data = await handleExportUsers({
      values: {
        limit: false,
        OrderAscending: orderConfig.direction,
        OrderBy: mappingOrderKeys[orderConfig.key],
        ...values,
      },
      loading: () => handleLoading(),
      error: () => handleError(),
      error_message: (msj) => handleErrorMessage(msj),
      service: getAllUsersService,
    });
    if (data) return data;
    else throw new Error("");
  };

  const handleDownload = async (type) => {
    const data = await handleExportUsers({
      values: {
        OrderAscending: orderConfig.direction,
        OrderBy: mappingOrderKeys[orderConfig.key],
        ...values,
      },
      loading: () => handleLoading(),
      error: () => handleError(),
      error_message: (msj) => handleErrorMessage(msj),
      service: exportUsersService,
      type,
    });
    if (data) return data;
    else throw new Error("");
  };

  // Effects
  useEffect(() => {
    getUsers();
  }, [
    refreshData,
    values?.search,
    values?.status,
    values?.register_date,
    values?.rol,
    pagination.page,
    orderConfig,
  ]);

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setStatusOptions,
      catalog: "status",
    });
  }, []);

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setRolOptions,
      catalog: "rol",
    });
  }, [refreshRol]);
  useEffect(() => {
    cargarEndpoints();
  }, []);

  const cargarEndpoints = async () => {
    await loadEndpoints();
    if (endpoints?.limit_export_user)
      num_limite_registros_export.current = endpoints.limit_export_user;
  };

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Avatar",
        custom_cell: ({ user }) => <UserProfilePhoto src={user.imagenPerfil} />,
      },
      {
        name: "Nombre(s)",
        selector: ({ user }) => user.nombre,
        key_name: "nombre",
        service: handleOrderByColumns,
      },
      {
        name: "Apellido Paterno",
        selector: ({ user }) => user.apellidoPaterno,
        key_name: "apellidoPaterno",
        service: handleOrderByColumns,
      },
      {
        name: "Apellido Materno",
        selector: ({ user }) => user.apellidoMaterno,
        key_name: "apellidoMaterno",
        service: handleOrderByColumns,
      },
      {
        name: "Correo electrónico",
        selector: ({ user }) => user.email,
        key_name: "email",
        service: handleOrderByColumns,
      },
      {
        name: "Roles",
        selector: ({ roles }) => {
          return <RolUser roles={roles} />;
        },
        service: handleOrderByColumns,
        key_name: "roles",
        cell_styles: {
          whiteSpace: "nowrap",
        },
      },
      {
        name: "Fecha de alta",
        selector: ({ user }) =>
          toUserTimeZone(formatDateToGMT06(user.fechaAlta)).format(
            "YYYY-MM-DD"
          ),
        key_name: "fechaAlta",
        service: handleOrderByColumns,
      },
      {
        name: "Estatus",
        custom_cell: ({ user, index, roles }) => (
          <div
            className={
              "w-100 p-0 m-0 " +
              (Permisos.verificarPermiso("Usuario", "Cambiar Estatus")
                ? ""
                : "pe-none")
            }
            data-testid="cambio-estatus-select"
          >
            <UserStatus
              status={user.status}
              id={user.id}
              name={`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`}
              index={index}
              rolesUser={roles}
            />
          </div>
        ),
        selector: ({ user }) => user.status,
        key_name: "status",
        service: handleOrderByColumns,
      },
      {
        name: "Acciones",
        custom_cell: ({ user }) => (
          <TableActions
            id={user.id}
            name={`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`}
            status={user.status}
          />
        ),
      },
    ],
    [handleOrderByColumns]
  );

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    orderConfig,
    pagination,
  ]);

  return (
    <UserContext.Provider
      value={{
        handleRefreshData,
        handleExport,
        statusOptions,
        rolOptions,
        handleRefreshRol,
        handleDownload,
      }}
    >
      {Permisos.verificarPermiso("Usuario", "Ver la sección") && (
        <div
          className="container-fluid d-flex flex-column p-0 module_users"
          style={{ position: "relative" }}
        >
          <div
            style={{ marginBottom: "11px" }}
            className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
          >
            {Permisos.verificarPermiso("Usuario", "Buscar") && (
              <FilterForm
                handleDatePickerChange={handleDatePickerChange}
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
                values={values}
                INITIAL_VALUES={INITIAL_VALUES}
              />
            )}
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap mt-3">
              <LimpiarFiltros
                handleChange={handleSelectChange}
                INITIAL_VALUES={get_INITIAL_VALUES()}
                modulo="Usuario"
              />
              {Permisos.verificarPermiso("Usuario", "Crear Rol") && (
                <CreateRol />
              )}
              {Permisos.verificarPermiso("Usuario", "Crear usuario") && (
                <CreateUser />
              )}
              {Permisos.verificarPermiso("Usuario", "Exportar") && (
                <ExportData />
              )}
            </div>
          </div>

          {memoizedUserTable}
        </div>
      )}
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
    </UserContext.Provider>
  );
}
