import React, { useState } from "react";
import { createPortal } from "react-dom";
import Loader from "../Loader/Loader";
import Validation from "./Validation";
import { toUserTimeZone, formatDateToGMT06 } from "../../services/data";

const Modal = ({ children }) => {
  return createPortal(
    <div className={`modal_container dco-details-transaccions-modal-container`}>
      {children}
    </div>,
    document.getElementById("root")
  );
};

export default function Details({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {isOpen && (
        <Modal>
          <div className=" position-relative dco_nodos-detalles">
            <div
              style={{ marginBottom: "23px" }}
              className="container-fluid p-0 d-flex flex-wrap flex-md-nowrap"
            >
              <div
                style={{
                  background: "#FFFFFF",
                  padding: "0",
                }}
                className="container-fluid d-flex justify-content-start align-items-start gap-1 "
              >
                <div
                  style={{ marginLeft: "10px" }}
                  className="container-fluid d-flex flex-column p-0 justify-content-start align-items-start main_header_container"
                >
                  <h4 className={"main_title"}>
                    {"Detalle del nodo"}
                    <span style={{ color: "#2D2D2D80", marginLeft: "5px" }}>
                      {` / ${data.nodo_name}`}
                    </span>
                  </h4>
                  <p className={"main_subtitle"}>
                    {"Ve el detalle del estatus del nodo"}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-1 align-items-center">
                <p className="text-nowrap m-0">
                  <b>Estatus del nodo:</b>
                </p>
                <Validation validation={data.status === "activo"} />
              </div>
            </div>
            <div className="d-flex flex-column shadow rounded mt-3 container-table">
              <div className="header-table d-flex flex-row justify-content-end align-items-center px-3">
                <div>
                  <span style={{ fontWeight: "700", color: "white" }}>
                    Fecha de corte:
                  </span>{" "}
                  {toUserTimeZone(data.last_heartbeat).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </div>
              </div>
              <div className="body-table d-flex flex-column flex-wrap">
                <div className="d-flex flex-row">
                  <b>Transacciones en el nodo:</b>
                </div>
                <div
                  className="d-flex flex-row"
                  style={{ paddingLeft: "10px", paddingTop: "9px" }}
                >
                  <div
                    className="d-flex flex-column"
                    style={{ minWidth: "220px" }}
                  >
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          minWidth: "67px",
                          marginRight: "32px",
                          color: "rgba(0, 0, 0, 0.60)",
                          fontWeight: "700",
                        }}
                      >
                        Coincidencia:
                      </div>{" "}
                      <div>{data.coincidencia ?? ""}</div>
                    </div>
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          minWidth: "67px",
                          marginRight: "32px",
                          color: "rgba(0, 0, 0, 0.60)",
                          fontWeight: "700",
                        }}
                      >
                        No existentes:
                      </div>{" "}
                      <div>{data.no_existentes ?? ""}</div>
                    </div>
                  </div>
                  <div className="d-flex flex-column w-50">
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          minWidth: "79px",
                          marginRight: "18px",
                          color: "rgba(0, 0, 0, 0.60)",
                          fontWeight: "700",
                        }}
                      >
                        Sin coincidencia:
                      </div>{" "}
                      <div>{data.sin_coincidencia ?? ""}</div>
                    </div>
                    <div
                      className="d-flex flex-row"
                      style={{ marginTop: "9px" }}
                    >
                      <div
                        style={{
                          minWidth: "79px",
                          marginRight: "18px",
                          color: "rgba(0, 0, 0, 0.60)",
                          fontWeight: "700",
                        }}
                      >
                        Totales:
                      </div>{" "}
                      <div>{data.total_transacciones}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row" style={{ paddingTop: "18px" }}>
                  <div
                    className="d-flex flex-column"
                    style={{ minWidth: "292px" }}
                  >
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          minWidth: "213px",
                          marginRight: "17px",
                          fontWeight: "700",
                        }}
                      >
                        Registros en el nodo:
                      </div>{" "}
                      <div>{data.registros_enrolados_total}</div>
                    </div>
                    <div
                      className="d-flex flex-row"
                      style={{ marginTop: "9px" }}
                    >
                      <div
                        style={{
                          minWidth: "213px",
                          marginRight: "17px",
                          fontWeight: "700",
                        }}
                      >
                        Índice de calidad del nodo:
                      </div>{" "}
                      <div>{data.nodo_data_quality_score.toFixed(2)}</div>
                    </div>
                    <div
                      className="d-flex flex-row"
                      style={{ marginTop: "9px" }}
                    >
                      <div
                        style={{
                          minWidth: "213px",
                          marginRight: "17px",
                          fontWeight: "700",
                        }}
                      >
                        Promedio del índice de calidad de fotografía:
                      </div>{" "}
                      <div>
                        {data.promedio_indice_calidad_foto.toFixed(2) ?? ""}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column w-50">
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          minWidth: "146px",
                          marginRight: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Cargas en el nodo:
                      </div>{" "}
                      <div>{data.total_cargas}</div>
                    </div>
                    <div
                      className="d-flex flex-row"
                      style={{ marginTop: "9px" }}
                    >
                      <div
                        style={{
                          minWidth: "146px",
                          marginRight: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Promedio tiempo de respuesta
                      </div>{" "}
                      <div>
                        {(data.promedio_tiempo_respuesta / 1000).toFixed(4) ??
                          ""}{" "}
                        seg
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              type="button"
              className="button button_active"
              style={{ marginTop: "15px" }}
              data-testid="close-modal-detalles"
            >
              Cerrar
            </button>
          </div>
        </Modal>
      )}
      <button
        style={{ fontSize: "9px" }}
        className="management_see_details_button"
        onClick={() => setIsOpen(true)}
        type="button"
      >
        Ver detalle
      </button>
      {loading && <Loader />}
    </>
  );
}
