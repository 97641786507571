/* funciones relacionadas con el manejo de datos que será utilizadas en diferentes componentes*/
import { useCallback, useEffect, useReducer } from 'react'
import html2canvas from "html2canvas";
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import { EncryptStorage } from 'encrypt-storage';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useMemo } from 'react';

/*Expresiones Regulares*/
export const EMAIL_REGEX = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
export const MIN_LENGTH = 12;
export const ONE_UPPERCASE = /[A-Z]/;
export const ONE_LOWERCASE = /[a-z]/;
export const NO_SEQUENCES =
  /(?:012|123|234|345|456|567|678|789|890|ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)/;
export const NO_SPACES = /^\s|\s$/;
export const PHONE_NUMBER = /^\d{10}$/;
export const NAMES = /^(?!\s)(?!.*\s{3,})(?=.*[a-zA-Z]).*$/;
export const TIME_ALERT = 5000;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);

export const daysOptions = [
  {
    label: "Últimas 24 horas",
    value: "last_24_hrs",
  },
  {
    label: "Esta semana",
    value: "this_week",
  },
  {
    label: "Última semana",
    value: "last_week",
  },
  {
    label: "Hoy",
    value: "today",
  },
  {
    label: "Últimos 90 días",
    value: "last_90_days",
  },
];

export const hoursOptions = [
  {
    label: "00:00",
    value: "00:00",
  },
  {
    label: "01:00",
    value: "01:00",
  },
  {
    label: "02:00",
    value: "02:00",
  },
  {
    label: "03:00",
    value: "03:00",
  },
  {
    label: "04:00",
    value: "04:00",
  },
  {
    label: "05:00",
    value: "05:00",
  },
  {
    label: "06:00",
    value: "06:00",
  },
  {
    label: "07:00",
    value: "07:00",
  },
  {
    label: "08:00",
    value: "08:00",
  },
  {
    label: "09:00",
    value: "09:00",
  },
  {
    label: "10:00",
    value: "10:00",
  },
  {
    label: "11:00",
    value: "11:00",
  },
  {
    label: "12:00",
    value: "12:00",
  },
  {
    label: "13:00",
    value: "13:00",
  },
  {
    label: "14:00",
    value: "14:00",
  },
  {
    label: "15:00",
    value: "15:00",
  },
  {
    label: "16:00",
    value: "16:00",
  },
  {
    label: "17:00",
    value: "17:00",
  },
  {
    label: "18:00",
    value: "18:00",
  },
  {
    label: "19:00",
    value: "19:00",
  },
  {
    label: "20:00",
    value: "20:00",
  },
  {
    label: "21:00",
    value: "21:00",
  },
  {
    label: "22:00",
    value: "22:00",
  },
  {
    label: "23:00",
    value: "23:00",
  },
  {
    label: "23:59",
    value: "23:59",
  },
];
/*Tratando Datos*/
export const blobToB64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
/*Validaciones Datos*/
export const verificarPassword = (password) => {
  let mensajeSalida = {
    min: false,
    oneUpperCase: false,
    oneLowerCase: false,
    noSecuence: false,
    noSpace: false,
    vacio: false,
    oneSpecialCaracter: false,
    minStrong: false
  };
  if (password.length >= MIN_LENGTH) {
    mensajeSalida.min = true;
  }
  if (password.match(ONE_UPPERCASE)) {
    mensajeSalida.oneUpperCase = true;
  }
  if (password.match(ONE_LOWERCASE)) {
    mensajeSalida.oneLowerCase = true;
  }
  if (!NO_SEQUENCES.test(password)) {
    mensajeSalida.noSecuence = true;
  }
  if (!NO_SPACES.test(password)) {
    mensajeSalida.noSpace = true;
  }
  if (!password.length) {
    mensajeSalida.vacio = true;
  }
  if (password.match(/[^a-zA-Z\d]/)) {
    mensajeSalida.oneSpecialCaracter = true;
  }
  if (password.length >= 16) {
    mensajeSalida.minStrong = true;
  }
  return mensajeSalida;
}

// manejador de catálogos

export const getEndDateAndTime = () => {
  const now = dayjs();
  return {
    end_date: now.format('YYYY-MM-DD'),
    end_time: now.format('HH:mm')
  };
}

export const handleGetCatalogs = async ({
  service,
  setter,
  catalog,
  error,
  error_message
}) => {
  try {
    const response = await service(catalog);
    if (!response.success) {
      if (response.type && response.type === 'NETWORK_CONNECTION') {
        error('increment', 'No hay conexión a Internet. Por favor, verifica tu conexión.')
        setTimeout(() => {
          error('decrement', '')
        }, TIME_ALERT);
      }
      console.error(response.message);
      error_message("Error al obtener los privilegios");
      setter([]);
      return;
    }
    if (catalog === 'nodes') {
      const { data } = response;
      data.forEach(element => {
        element.name_select = element.nodo_id;
      });
      setter(data);
    } else
      setter(response.data);
    return;
  } catch (error) {
    setter([]);
    console.error("Error request");
    console.error(error);
    return;
  }
};


export const handleGetPrivileges = async ({
  service,
  setter,
  error_message,
  setLoading
}) => {
  try {
    setLoading(true);
    const response = await service();
    if (!response.success) {
      setLoading(false);
      console.error(response.message);
      error_message(response.message);
      setter([]);
      setTimeout(() => {
        error_message(null);
      }, TIME_ALERT)
      return;
    }
    const groupedData = response.data.reduce((acc, item) => {
      const { module } = item;
      if (!acc[module]) {
        acc[module] = [];
      }
      acc[module].push(item);
      return acc;
    }, {});
    setLoading(false);
    setter(groupedData);
    return;
  } catch (error) {
    setLoading(false);
    console.error(error);
    error_message("Error al obtener los privilegios");
    setter([]);
    setTimeout(() => {
      error_message(null);
    }, TIME_ALERT)
    return;
  }
};

export const downloadDivisorHtml = async (divisor) => {
  let canvasPromise = html2canvas(divisor, {
    useCORS: true // in case you have images stored in your application
  });
  let canvas = await canvasPromise;
  let dataURL = canvas.toDataURL("image/png");
  // Create an image element from the data URL
  let img = new Image();
  img.src = dataURL;
  img.download = "Grafica.png";
  // Create a link element
  let a = document.createElement("a");
  a.innerHTML = "DOWNLOAD";
  a.target = "_blank";
  a.href = img.src;
  a.download = img.download;
  a.click();
}

export const openDivHtmlWindow = (div, rutasEstilos, rutasAbsolutasEstilos, colorFondo, scriptText) => {
  let dividerElement = div.cloneNode(true); //copiando nodo

  //Agregando color fondo si no se usa por defult el de body
  if (colorFondo) {
    let newDivisor = window.document.createElement("div");
    newDivisor.append(dividerElement)
    newDivisor.style.backgroundColor = colorFondo;
    dividerElement = newDivisor;
  }
  let dividerHTML = dividerElement.innerHTML;

  let newWindow = window.open('', '_blank'); // abriendo nueva pestaña

  if (!newWindow) //si ocurrió un error al abrir la pestaña
    return false;
  //Agregando estilos
  if (rutasEstilos)
    for (let index = 0; index < rutasEstilos.length; index++) {
      const ruta = rutasEstilos[index];
      let styles = window.document.createElement("link");
      styles.rel = "stylesheet";
      styles.href = window.location.origin + "/" + ruta;
      newWindow.document.head.append(styles)
    }
  if (rutasAbsolutasEstilos)
    for (let index = 0; index < rutasAbsolutasEstilos.length; index++) {
      const ruta = rutasAbsolutasEstilos[index];
      let styles = window.document.createElement("link");
      styles.rel = "stylesheet";
      styles.href = ruta;
      newWindow.document.head.append(styles)
    }
  //Agregando lógica JS//
  if (scriptText) {
    let script = window.document.createElement("script");
    script.textContent = scriptText;
    script.type = 'text/javascript';
    newWindow.document.head.append(script);
  }
  newWindow.document.body.innerHTML = dividerHTML;
  return newWindow;
}

/**
 * Función para el manejo del input de rango de fechas.
 * Es una función de orden superior que recibe:
 * 
 * - setter: Una función encargada de actualizar el estado que controla el rango de fechas.
 * - withName (opcional, por defecto true): Un booleano que indica si el setter debería recibir
 *   también el nombre (id) del input.
 * 
 * Esta función devuelve un manejador que formatea las fechas recibidas en el formato "YYYY-MM-DD".
 * El objeto resultante tiene la siguiente estructura:
 * {
 *   "start": "YYYY-MM-DD",
 *   "end": "YYYY-MM-DD"
 * }
 * 
 * Si alguna fecha es inválida, se devuelve la siguiente cadena "Invalid Date".
 */

export const createDateRangeChangeHandler = ({ setter, withName = true }) => {
  const handler = ({ name, value }) => {
    const [startDate, endDate] = value;
    const valueSet = {
      start: dayjs(startDate).format("YYYY-MM-DD") !== "Invalid Date"
        ? dayjs(startDate).format("YYYY-MM-DD")
        : "",
      end: dayjs(endDate).format("YYYY-MM-DD") !== "Invalid Date"
        ? dayjs(endDate).format("YYYY-MM-DD")
        : ""
    };
    const params = withName ? [name, valueSet] : [valueSet];
    setter(...params);
  }
  return handler;
};

export const createDatePickerChangeHandler = ({ setter, startDate, endDate }) => {
  const handleDatePickerChange = ({ name, value }) => {
    const formattedValue =
      dayjs(value).format("YYYY-MM-DD") !== "Invalid Date"
        ? dayjs(value).format("YYYY-MM-DD")
        : "";
    // Ajustes para validar que el rango de fecha no sea menor a la fecha de inicio
    if (name === 'start_date' && endDate && dayjs(formattedValue).isAfter(dayjs(endDate))) {
      console.warn("La fecha de inicio no puede ser mayor que la fecha final");
      return;
    }

    if (name === 'end_date' && startDate && dayjs(formattedValue).isBefore(dayjs(startDate))) {
      console.warn("La fecha final no puede ser menor que la fecha de inicio");
      return;
    }

    setter(name, formattedValue);
  };
  return handleDatePickerChange
}

export const generateDataReport = (data) => {
  const object = {
    texts: {},
    lines: {},
    roundedRects: {}
  };
  let xText1 = 14;
  let yText1 = 95;
  let xText2 = 22;
  let xText3 = 165;
  let yLine = 103;
  let yRoundedRect = 89;
  const length = data.length;
  data.forEach((value, index) => {
    object.texts = {
      ...object.texts, [index]: {
        text: value.index,
        x: xText1,
        y: yText1,
        font: {
          fontName: 'Mulish',
          fontStyle: 'normal',
          size: 10,
          textColor: '#2D2D2D',
          lineHeight: 12.55,
        }
      }, [`${value.code}`]: {
        text: value.message,
        x: xText2,
        y: yText1,
        font: {
          fontName: 'Mulish',
          fontStyle: 'normal',
          size: 8,
          textColor: '#2D2D2D',
          lineHeight: 10.04,
        }
      }, [`registros${value.code}`]: {
        text: (value.total > 0) ? `${value.total} Registros con error` : 'Registros correctos',
        x: (value.total > 0) ? (xText3 - 2) : xText3,
        y: yText1,
        font: {
          fontName: 'Mulish',
          fontStyle: 'normal',
          size: 9,
          textColor: (value.total > 0) ? '#FF0000' : '#018559',
          lineHeight: 11.3,
        }
      },
    };
    object.roundedRects = {
      ...object.roundedRects, [index]: {
        x: 160,
        y: yRoundedRect,
        w: 37,
        h: 10,
        rx: 5,
        ry: 5,
        styles: {
          strokeColor: value.total > 0 ? '#FF0000' : '#018559'
        }
      }
    }
    if ((index + 1) !== length) {
      object.lines = {
        ...object.lines, [index]: {
          x1: 12,
          y1: yLine,
          x2: 197,
          y2: yLine,
          styles: {
            strokeColor: '#D9D9D9'
          }
        }
      };
    };
    yText1 = yText1 + 18;
    yLine = yLine + 18;
    yRoundedRect = yRoundedRect + 18;
  })
  return object
}

export const getImageGraphics = async (divisor) => {
  let canvasPromise = html2canvas(divisor, {
    useCORS: true // in case you have images stored in your application
  });
  let canvas = await canvasPromise;
  let dataURL = canvas.toDataURL("image/png");
  return dataURL;
}

export const formatNumberWithCommas = (number) => {
  const formattedNumber = Number(number);
  return formattedNumber;
}

export const calculateCoordinates = (number, value) => {
  for (let index = 6; index < number.toString().length; index++) {
    value = value + 5;
  }
  return value
}

export const formatTextTrx = (texto, longitud) => {
  if (texto.length <= longitud) {
    // Devuelve el texto sin cambios si la longitud es menor o igual a la longitud especificada
    return texto;
  } else {
    const inicio = texto.slice(0, 6);
    const final = texto.slice(-4);
    return inicio + '...' + final;  // Agrega puntos suspensivos en el centro
  }
}
function createRandomString(length) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const randomArray = new Uint8Array(length);
  crypto.getRandomValues(randomArray);
  randomArray.forEach((number) => {
    result += chars[number % chars.length];
  });
  return result;
}

export const encryptStorage = new EncryptStorage(process.env.REACT_APP_TOKEN_SECURITY_PASS || "hVNDhH7gfMb94Kt8HvbfGykX5fejvNm", { storageType: "sessionStorage" });

export function formatIDSummary(id) {
  let res = ""
  if (id) {
    let prv = id.toString();
    res = prv.substr(0, 7) + "..." + prv.substr(-7);
  }
  return res;
}

export const formatHour = (hour) => {
  /*if (hour < 0 || hour > 23) {
    throw new Error("Hour must be between 0 and 23");
  }*/
  if (hour < 0 || hour > 23) {
    return "23:59"
  }
  const formattedHour = hour.toString().padStart(2, '0');
  return `${formattedHour}:00`;
};

export const parseHoursToInt = (time) => {
  const hours = parseInt(time.split(":")[0], 10);
  return hours;
}

export const get_last_24_hrs = () => {
  return ({
    start_date: dayjs().subtract(1, 'day').format("YYYY-MM-DD"),
    start_time: `${dayjs().hour().toString().padStart(2, '0')}:00}`,//${dayjs().minute().toString().padStart(2, '0')
    end_date: dayjs().format("YYYY-MM-DD"),
    end_time: `${dayjs().hour().toString().padStart(2, '0')}:00`//${dayjs().minute().toString().padStart(2, '0')}
  })
}
export const get_this_week = () => {
  return ({
    start_date: dayjs().subtract(dayjs().day() - 1, 'day').format("YYYY-MM-DD"),
    start_time: '00:00',
    end_date: dayjs().format("YYYY-MM-DD"),
    end_time: `${dayjs().hour().toString().padStart(2, '0')}:00`//${dayjs().minute().toString().padStart(2, '0')}
  })
}
export const get_last_week = () => {
  let lastMonday = dayjs().subtract(dayjs().day() + 6, 'day').startOf('day');
  let lastSunday = lastMonday.add(6, 'day').endOf('day');
  return ({
    start_date: lastMonday.format("YYYY-MM-DD"),
    start_time: '00:00',
    end_date: lastSunday.format("YYYY-MM-DD"),
    end_time: '23:59'
  })
}
export const get_today = () => {
  return ({
    start_date: dayjs().format("YYYY-MM-DD"),
    start_time: '00:00',
    end_date: dayjs().format("YYYY-MM-DD"),
    end_time: `${dayjs().hour().toString().padStart(2, '0')}:00` //${dayjs().minute().toString().padStart(2, '0')}
  })
}
export const get_last_90_days = () => {
  return ({
    start_date: dayjs().subtract(90, 'day').format("YYYY-MM-DD"),
    start_time: '00:00',
    end_date: dayjs().format("YYYY-MM-DD"),
    end_time: formatHour(dayjs().hour())
  })
}
export const get_this_year = () => {
  return ({
    start_date: dayjs().startOf('year').format("YYYY-MM-DD"),
    start_time: '00:00',
    end_date: dayjs().format("YYYY-MM-DD"),
    end_time: formatHour(dayjs().hour())
  })
}
export const get_last_12_months = () => {
  return ({
    start_date: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    start_time: '00:00',
    end_date: dayjs().format("YYYY-MM-DD"),
    end_time: formatHour(dayjs().hour())
  })
}
export const toUserTimeZone = (date) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const providedTimeUTC = dayjs.tz(date, 'UTC');
  return providedTimeUTC.tz(userTimeZone);
}

export const toUtcTimeZone = (date) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const providedTimeInUserTZ = dayjs.tz(date, userTimeZone);
  return providedTimeInUserTZ.utc();
}

export const formatDateToGMT06 = (date) => {
  if (typeof date !== 'string' || !date.trim()) {
    return '';
  }
  let fecha = dayjs.utc(date);
  if (!fecha.isValid()) {
    fecha = dayjs.utc(date + "+00:00");
  }

  const fechaMexico = fecha.tz("America/Mexico_City").format('YYYY-MM-DD HH:mm:ss');
  return fechaMexico;
};

export const translateTransactionsSerach = (text) => {
  console.log("Text", text);
  
  return text.toUpperCase().includes("POSITIVO") ? `${true}&searchfield=match` : (text.toUpperCase().includes("NEGATIVO") ? `${false}&searchfield=match` : text)
};

export const validateEmptyValues = (obj) => {
  return Object.keys(obj)
    .filter(key => obj[key] !== "")
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

// React component exportation to png

export const handleCapture = async (key, captureRefs, fetchFallbackImage) => {
  const element = captureRefs.current[key];
  if (element) {
    try {
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');

      if (isValidBase64Image(imgData)) {
        return imgData;
      } else {
        console.error('Generated image is not valid.');
        return fetchFallbackImage();
      }
    } catch (error) {
      console.error(`Error capturing element for key ${key}:`, error);
      return fetchFallbackImage();
    }
  } else {
    throw new Error(`No element found for key: ${key}`);
  }
};

export const setRef = (key, el, captureRefs) => {
  captureRefs.current[key] = el;
};

const isValidBase64Image = (base64String) => {
  return base64String.startsWith('data:image/png;base64,');
};

export const fetchFallbackImage = () => {
  const fallbackImageURL = '/images/dashboard/noimage.png';
  return fallbackImageURL;
};

export const opacityOptions = [
  '#6236FF',
  '#6236FFB3',
  '#6236FF99',
  '#6236FF80',
  '#6236FF66',
  '#6236FF59',
  '#6236FF4D',
  '#6236FF40',
  '#6236FF33',
  '#6236FF1A',
  '#f0ebff',
  // '#6236FF',
  // '#9172ff',
  // '#9172ff',
  // '#9172ff',
  // '#b19aff',
  // '#b19aff',
  // '#b19aff',
  // '#d7cdff',
  // '#d7cdff',
  // '#f0ebff',
];

export const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

export const dowloadLinkFileBlob = (blob, name) => {
  try {
    let downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);;
    downloadLink.download = name;
    downloadLink.click();
    return { success: true }
  } catch (error) {
    return { success: false, error: error }
  }

}

// Error reducer 

export const initialError = {
  error: 0,
  message: '',
}

export const errorReducer = (state, action) => {
  switch (action.type) {
    case "decrement": {
      if (state.error - 1 === 0) {
        return initialError
      }
      return {
        ...state,
        error: state.error - 1
      }
    }
    case "increment":
      return {
        message: action.message,
        error: state.error + 1
      };
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

export const useErrorReducer = () => {
  const [errorValues, dispatchError] = useReducer(errorReducer, initialError);

  const handleError = (type, message) => {
    dispatchError({
      type,
      message
    });
  };

  return {
    errorValues,
    handleError
  }
}


export const initialMultipleError = {
  errors: [],
  text: '',
  show: false,
};

export const multipleErrorReducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      return {
        ...state,
        errors: [...state.errors, action.value],
      };
    }
    case 'set_text': {
      return {
        ...state,
        text: action.value,
      };
    }
    case 'show': {
      return {
        ...state,
        show: true,
      };
    }
    case 'hide': {
      return {
        ...state,
        show: false,
      };
    }
    case 'reset': {
      return initialMultipleError;
    }
    default: {
      throw new Error('Unknown action: ' + action.type);
    }
  }
};

export const useMultipleErrorReducer = () => {
  const [errorValues, dispatchError] = useReducer(
    multipleErrorReducer,
    initialMultipleError,
  );

  const setText = useCallback((text) => {
    dispatchError({
      type: 'set_text',
      value: text,
    });
  }, []);

  const addError = useCallback(
    (error) => {
      dispatchError({
        type: 'add',
        value: error,
      });
    },
    [],
  );

  const handleError = useCallback(() => {
    dispatchError({
      type: 'show',
    });
    setTimeout(() => {
      dispatchError({
        type: 'hide',
      });
      dispatchError({
        type: 'reset',
      });
    }, TIME_ALERT);
  }, []);

  useEffect(() => {
    if (errorValues.errors.length > 0) {
      if (errorValues.text === '')
        setText('Ocurrieron los siguientes errores: ');
    }
  }, [errorValues.errors.length, errorValues.text, setText])

  return {
    errorValues,
    addError,
    setText,
    handleError,
  };
};

/* Starts values reducer */


export const filterHoursOptions = ({start_date, start_hours, end_date, end_hours}) => {
  const startDateTime = dayjs(`${start_date} ${start_hours}`);
  const endDateTime = dayjs(`${end_date} ${end_hours}`);

  if (startDateTime.isSame(endDateTime, 'day')) {
    return hoursOptions.filter((option) =>
      dayjs(`${start_date} ${option.value}`).isBefore(endDateTime)
    );
  }

  return hoursOptions;
};

const vluesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export const useValuesReducer = (INITIAL_VALUES) => {
  const [values, dispatch] = useReducer(vluesReducer, INITIAL_VALUES);

  const setFieldValue = (field, value) => {
    dispatch({ type: 'SET_FIELD', field, value });
  };

  const createHandleChange = (callback) => {
    return ({ name, value }) => {
      if (callback) callback();
      setFieldValue(name, value);
    };
  };

  const createHandleDatePickerChange = (callback) => {
    return ({ name, value }) => {
      if (callback) callback();
      const valueSet =
        dayjs(value).format('YYYY-MM-DD') !== 'Invalid Date'
          ? dayjs(value).format('YYYY-MM-DD')
          : '';
      setFieldValue(name, valueSet);
    };
  };

  const createHandleDatePickerRangeChange = (callback) => {
    return ({ name, value }) => {
      if (callback) callback();
      const [startDate, endDate] = value;
      const valueSet = {
        start:
          dayjs(startDate).format('YYYY-MM-DD') !== 'Invalid Date'
            ? dayjs(startDate).format('YYYY-MM-DD')
            : '',
        end:
          dayjs(endDate).format('YYYY-MM-DD') !== 'Invalid Date'
            ? dayjs(endDate).format('YYYY-MM-DD')
            : '',
      };
      setFieldValue(name, valueSet);
    };
  };

  const last_24_hrs = useCallback(
    ({ start_date, start_hours, end_date, end_hours }) => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        start_date,
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue(start_hours, dayjs().format("HH:mm"));
      setFieldValue(end_date, endDateAndHours.end_date);
      setFieldValue(end_hours, endDateAndHours.end_time);
    },
    [],
  );

  const this_week = useCallback(
    ({ start_date, start_hours, end_date, end_hours }) => {
      const endDateAndHours = getEndDateAndTime();
      const startOfWeek = dayjs().isoWeekday(1);
      setFieldValue(start_date,startOfWeek.format('YYYY-MM-DD'));
      setFieldValue(start_hours, "00:00");
      setFieldValue(end_date, endDateAndHours.end_date);
      setFieldValue(end_hours, endDateAndHours.end_time);
    },
    [],
  );

  const last_week = useCallback(
    ({ start_date, start_hours, end_date, end_hours }) => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        start_date,
        dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue(start_hours, "00:00");
      setFieldValue(end_date, endDateAndHours.end_date);
      setFieldValue(end_hours, endDateAndHours.end_time);
    },
    [],
  );
  const today = useCallback(
    ({ start_date, start_hours, end_date, end_hours }) => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(start_date, dayjs().format('YYYY-MM-DD'));
      setFieldValue(start_hours, "00:00");
      setFieldValue(end_date, endDateAndHours.end_date);
      setFieldValue(end_hours, endDateAndHours.end_time);
    },
    [],
  );
  const last_90_days = useCallback(
    ({ start_date, start_hours, end_date, end_hours }) => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        start_date,
        dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue(start_hours, dayjs().format("HH:mm"));
      setFieldValue(end_date, endDateAndHours.end_date);
      setFieldValue(end_hours, endDateAndHours.end_time);
    },
    [],
  );
  const this_year = useCallback(
    ({ start_date, start_hours, end_date, end_hours }) => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        start_date,
        dayjs().subtract(dayjs().month(), 'month').format('YYYY-MM-DD'),
      );
      setFieldValue(start_hours, dayjs().format("HH:mm"));
      setFieldValue(end_date, endDateAndHours.end_date);
      setFieldValue(end_hours, endDateAndHours.end_time);
    },
    [],
  );
  const last_12_months = useCallback(
    ({ start_date, start_hours, end_date, end_hours }) => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        start_date,
        dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      );
      setFieldValue(start_hours, dayjs().format("HH:mm"));
      setFieldValue(end_date, endDateAndHours.end_date);
      setFieldValue(end_hours, endDateAndHours.end_time);
    },
    [],
  );

  const daysController = useMemo(
    () => ({
      last_24_hrs: (params) => last_24_hrs(params),
      this_week: (params) => this_week(params),
      last_week: (params) => last_week(params),
      today: (params) => today(params),
      last_90_days: (params) => last_90_days(params),
      this_year: (params) => this_year(params),
      last_12_months: (params) => last_12_months(params),
    }),
    [
      last_12_months,
      last_24_hrs,
      last_90_days,
      last_week,
      this_week,
      this_year,
      today,
    ],
  );

  return {
    values,
    createHandleChange,
    createHandleDatePickerChange,
    createHandleDatePickerRangeChange,
    setFieldValue,
    daysController
  };
};
export const REGEXInputNombre = [
  {
    value: "^[a-zA-Z0-9]{1}$",
    message: "Debe contener al menos dos caracteres",
    coincidencia: false,
  },
  {
    value: "\\d",
    message: "No se permiten números",
    coincidencia: false,
  },
  {
    value: "[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\\s]",
    message: "No se permiten caracteres especiales",
    coincidencia: false,
  },
];


export const initialLoading = {
  loading: 0,
};

export const loaderReducer = (state, action) => {
  switch (action.type) {
    case 'decrement': {
      return {
        loading: state.loading - 1,
      };
    }
    case 'increment':
      return {
        loading: state.loading + 1,
      };
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

export const useLoaderReducer = () => {
  const [loaderValues, dispatchLoader] = useReducer(
    loaderReducer,
    initialLoading,
  );

  const handleLoader = (type, message) => {
    dispatchLoader({
      type,
      message,
    });
  };

  return {
    loaderValues,
    handleLoader,
  };
};

export const getMaxStartHour = ({ start_date, start_hours, end_date, end_hours }) => {
  const startDateTime = dayjs(`${start_date} ${start_hours}`);
  const endDateTime = dayjs(`${end_date} ${end_hours}`);

  if (startDateTime.isSame(endDateTime, 'day')) {
    return endDateTime.subtract(1,'minute');
  }
  return null;
};

export const getMinEndHour = ({ start_date, start_hours, end_date, end_hours }) => {
  const startDateTime = dayjs(`${start_date} ${start_hours}`);
  const endDateTime = dayjs(`${end_date} ${end_hours}`);

  if (startDateTime.isSame(endDateTime, 'day')) {
    return startDateTime.add(1,'minute');
  }
  return null;
};

export const getMaxEndHour = ({ end_date, end_hours }) => {
  const endDateTime = dayjs(`${end_date} ${end_hours}`);
  const now = dayjs();
  if (now.isSame(endDateTime, 'day')) {
    return now;
  }
  return null;
};

export const getDefaultInitialDateValues = () => ({
  start_date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  start_hours: dayjs().format("HH:mm"),
  end_date: dayjs().format("YYYY-MM-DD"),
  end_hours: dayjs().format("HH:mm"),
  days: "last_24_hrs",
});

export const formatToTwoDecimals = (number) => {
  try {
    const parsedNumber = parseFloat(number);
    if (isNaN(parsedNumber)) {
      return "0.00";
    }
    return parsedNumber.toFixed(2);
  } catch (error) {
    return "0.00";
  }
};
