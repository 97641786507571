import React, { useState, useRef, useCallback, useEffect } from "react";
// Libraries
import dayjs from "dayjs";
import ReportePDF from "dicio-npm-reporte-pdf";
// Components
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import StatisticsConsorcio from "./StatisticsConsorcio";
import ModalExportDashboard from "./ModalExportDashboard";
import FilterDashboard from "./FilterDashboard";
import TransactionsByNodeChart from "../Graficas/Consorcio/TransactionsByNodeChart";
import TimeAverageConsorcioChart from "../Graficas/Consorcio/TimeAverageConsorcioChart";
import TransactionsConsorcioChart from "../Graficas/Consorcio/TransactionsConsorcioChart";
import { endpoints, loadEndpoints } from "../../services/api";

// Data
import {
  formatNumberWithCommas,
  handleCapture,
  setRef,
  fetchFallbackImage,
  handleGetCatalogs,
  useValuesReducer,
  toUtcTimeZone,
  getDefaultInitialDateValues,
  TIME_ALERT,
  formatToTwoDecimals,
} from "../../services/data";

// Services
import {
  getMetricsAll,
  getNodeCatalog,
  getStatisticsConsorcioAll,
  getTransactionsAllNodes,
} from "../../services/api";
import LimpiarFiltros from "../Sharing/LimpiarFiltros";
import { Permisos } from "../../services/permisos";

// Constants
const widthConversionFactor = 0.361;
const heightConversionFactor = 0.392;
const get_INITIAL_VALUES = () => {
  return {
    ...getDefaultInitialDateValues(),
  };
};
const INITIAL_VALUES = get_INITIAL_VALUES();

// Functions
const convertWidth = (pixels) => {
  return pixels * widthConversionFactor;
};

const convertHeight = (pixels) => {
  return pixels * heightConversionFactor;
};

const availableCharts = {
  TransactionsConsorcioChart: "TransactionsConsorcioChart",
  TransactionsByNodeChart: "TransactionsByNodeChart",
  promedio_tiempo_respuesta_nodo: "promedio_tiempo_respuesta_nodo",
};

const ConsorcioStatisticsData = () => {
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  const captureRefs = useRef({});
  const [padding, setPadding] = useState(0);
  const [propsPDF, setPropsPDF] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataCharts, setDataCharts] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [nodesCatalog, setNodesCatalog] = useState({});
  const [typeAlert, setTypeAlert] = useState("success");
  const [alertMessage, setAlertMessage] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [statisticsByDate, setStatisticsByDate] = useState("");
  const [transactionsByNode, setTransactionsByNode] = useState({});
  const [statisticsKPI, setStatisticsKPI] = useState({});
  const [nameGeneral, setNameGeneral] = useState("");
  useEffect(() => {
    const cargarEndpoint = async () => {
      await loadEndpoints();
      let name =
        endpoints?.nodo_name || process.env.REACT_APP_CONSTANT_NAME_NODO || "";
      setNameGeneral(name);
    };
    cargarEndpoint();
  }, []);

  const getConsorcioStatisticsData = useCallback(async (values) => {
    let responseDay = await getMetricsAll(
      toUtcTimeZone(
        `${values.start_date} ${
          values.start_hours !== "" ? values.start_hours : "00:00"
        }`
      ).toISOString(),
      toUtcTimeZone(
        `${values.end_date} ${
          values.end_hours !== ""
            ? values.end_hours === "23:59"
              ? `${values.end_hours}:59`
              : values.end_hours
            : "00:00"
        }`
      ).toISOString()
    );
    if (responseDay.success) {
      setStatisticsByDate(responseDay?.data ?? []);
      setLoading(false);
    } else {
      setStatisticsByDate("");
      responseDay.data.Respuesta
        ? setAlertMessage(responseDay.data.Respuesta)
        : setAlertMessage(
            responseDay.message ??
              "No se encontraron registros para los valores proporcionados, por favor intenta nuevamente."
          );
      setTypeAlert("error");
      setShowAlert(true);
      limpiarError();
    }
  }, []);
  const getStatisticsKPI = useCallback(async () => {
    let responseDate = await getMetricsAll(
      toUtcTimeZone(`2024-01-01 00:00`).toISOString(),
      toUtcTimeZone(
        `${INITIAL_VALUES.end_date} ${
          INITIAL_VALUES.end_hours !== ""
            ? INITIAL_VALUES.end_hours === "23:59"
              ? `${INITIAL_VALUES.end_hours}:59`
              : INITIAL_VALUES.end_hours
            : "00:00"
        }`
      ).toISOString()
    );
    if (responseDate.success) {
      setStatisticsKPI(responseDate?.data ?? {});
    } else {
      setStatisticsKPI({});
      responseDate.data.Respuesta
        ? setAlertMessage(responseDate.data.Respuesta)
        : setAlertMessage(
            responseDate.message ??
              "No se encontraron registros para los valores proporcionados, por favor intenta nuevamente."
          );
      setTypeAlert("error");
      setShowAlert(true);
      limpiarError();
    }
  }, [
    INITIAL_VALUES.start_hours,
    INITIAL_VALUES.end_date,
    INITIAL_VALUES.end_hours,
    INITIAL_VALUES.start_hours,
  ]);

  const getChartsData = useCallback(async (values) => {
    let responseCharts = await getStatisticsConsorcioAll(
      toUtcTimeZone(
        `${values.start_date} ${
          values.start_hours !== "" ? values.start_hours : "00:00"
        }`
      ).toISOString(),
      toUtcTimeZone(
        `${values.end_date} ${
          values.end_hours !== ""
            ? values.end_hours === "23:59"
              ? `${values.end_hours}:59`
              : values.end_hours
            : "00:00"
        }`
      ).toISOString()
    );
    if (responseCharts.success) {
      setDataCharts(responseCharts.data);
    } else {
      setDataCharts("");
      setAlertMessage(
        responseCharts.data.Respuesta ??
          "No se encontraron registros para los valores proporcionados, por favor intenta nuevamente."
      );
      setTypeAlert("error");
      setShowAlert(true);
      limpiarError();
    }
  }, []);

  const getChartPieData = useCallback(async (values) => {
    let responseCharts = await getTransactionsAllNodes(
      toUtcTimeZone(
        `${values.start_date} ${
          values.start_hours !== "" ? values.start_hours : "00:00"
        }`
      ).toISOString(),
      toUtcTimeZone(
        `${values.end_date} ${
          values.end_hours !== ""
            ? values.end_hours === "23:59"
              ? `${values.end_hours}:59`
              : values.end_hours
            : "00:00"
        }`
      ).toISOString()
    );
    if (responseCharts.success) {
      setTransactionsByNode(responseCharts.data);
    } else {
      setTransactionsByNode("");
      setAlertMessage(
        responseCharts.data.Respuesta ??
          "No se encontraron registros para los valores proporcionados, por favor intenta nuevamente."
      );
      setTypeAlert("error");
      setShowAlert(true);
      limpiarError();
    }
  }, []);

  const promisesData = useCallback(
    (values) => {
      setLoading(true);
      Promise.all([
        getConsorcioStatisticsData(values),
        getChartsData(values),
        getChartPieData(values),
      ])
        .then((e) => setLoading(false))
        .catch((err) => {
          setLoading(false);
          console.warn(
            `✨ ~ file: DashboardComponent.jsx:101 ~ useEffect ~ err:`,
            err
          );
          if (
            err.message === "NETWORK_CONNECTION" ||
            err.message === "Failed to fetch"
          )
            setAlertMessage(
              "No hay conexión a Internet. Por favor, verifica tu conexión."
            );
          else {
            setAlertMessage(
              "No se lograron obtener resultados, por favor selecciona nuevamente la fecha"
            );
          }
          setTypeAlert("error");
          setShowAlert(true);
          limpiarError();
        });
    },
    [getChartPieData, getChartsData, getConsorcioStatisticsData]
  );

  useEffect(() => {
    handleGetCatalogs({
      service: getNodeCatalog,
      setter: setNodesCatalog,
      catalog: "nodes",
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", validateResolution);
    validateResolution();
  }, []);

  const limpiarGraficas = () => {
    setStatisticsByDate("");
    setDataCharts("");
    setTransactionsByNode("");
  };

  const handleChange = createHandleChange();

  const handleDatePickerChange = createHandleDatePickerChange();

  const limpiarError = () => {
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, TIME_ALERT);
  };

  const getObjectDocument = (doc) => {
    if (doc) {
      setShowAlert(true);
      setTypeAlert("success");
      setAlertMessage("La exportación de datos se ha realizado con éxito.");
      limpiarError();
      doc.save(`Información dashboard_${nameGeneral}.pdf`);
      setLoadingExport(false);
      setPropsPDF({});
    } else {
      setShowAlert(true);
      setTypeAlert("error");
      setAlertMessage(
        "Ocurrió un error al momento de generar el reporte, por favor intenta nuevamente"
      );
      limpiarError();
    }
  };

  useEffect(() => {
    if (values.days !== "") {
      //setLoading(true)
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  useEffect(() => {
    limpiarGraficas();
    promisesData(values);
  }, [
    values.end_date,
    values.end_hours,
    values.start_date,
    values.start_hours,
  ]);
  useEffect(() => {
    setLoading(true);
    Promise.all([getStatisticsKPI()])
      .then((e) => setLoading(false))
      .catch((err) => {
        debugger;
        setLoading(false);
        console.warn(
          `✨ ~ file: DashboardComponent.jsx:101 ~ useEffect ~ err:`,
          err
        );
        if (
          err.message === "NETWORK_CONNECTION" ||
          err.message === "Failed to fetch"
        )
          setAlertMessage(
            "No hay conexión a Internet. Por favor, verifica tu conexión."
          );
        else {
          setAlertMessage(
            "No se lograron obtener resultados, por favor selecciona nuevamente la fecha"
          );
        }
        setTypeAlert("error");
        setShowAlert(true);
        limpiarError();
      });
  }, []);

  const getError = (error) => {
    console.log(`🚀 ----------------------------🚀`);
    console.log(`🚀 ~ getError ~ error:`, error);
    console.log(`🚀 ----------------------------🚀`);
  };

  const getPieChartPDF = (type) => {
    if (captureRefs?.current?.TransactionsByNodeChart?.offsetWidth >= 450)
      return type === "width"
        ? convertWidth(
            captureRefs?.current?.TransactionsByNodeChart?.offsetWidth * 0.35
          )
        : convertHeight(
            captureRefs?.current?.TransactionsByNodeChart?.offsetHeight * 0.3
          );
    else if (
      captureRefs?.current?.TransactionsByNodeChart?.offsetWidth > 300 &&
      captureRefs?.current?.TransactionsByNodeChart?.offsetWidth < 450
    )
      return type === "width"
        ? convertWidth(
            captureRefs?.current?.TransactionsByNodeChart?.offsetWidth * 0.55
          )
        : convertHeight(
            captureRefs?.current?.TransactionsByNodeChart?.offsetHeight * 0.5
          );
    else if (
      captureRefs?.current?.TransactionsByNodeChart?.offsetWidth >= 259 &&
      captureRefs?.current?.TransactionsByNodeChart?.offsetWidth <= 300
    )
      return type === "width"
        ? convertWidth(
            captureRefs?.current?.TransactionsByNodeChart?.offsetWidth * 0.6
          )
        : convertHeight(
            captureRefs?.current?.TransactionsByNodeChart?.offsetHeight * 0.55
          );
    else if (captureRefs?.current?.TransactionsByNodeChart?.offsetWidth < 259)
      return type === "width"
        ? convertWidth(
            captureRefs?.current?.TransactionsByNodeChart?.offsetWidth * 0.65
          )
        : convertHeight(
            captureRefs?.current?.TransactionsByNodeChart?.offsetHeight * 0.55
          );
    else
      return type === "width"
        ? convertWidth(
            captureRefs?.current?.TransactionsByNodeChart?.offsetWidth * 0.45
          )
        : convertHeight(
            captureRefs?.current?.TransactionsByNodeChart?.offsetHeight * 0.4
          );
  };

  const exportarGraficasImagen = async () => {
    try {
      // setLoadingExport(true);
      const objetoProvisional = {};
      const objetoProps = {
        numberPages: 0,
        content: [],
      };

      objetoProvisional.configuration = {
        compress: true,
      };
      const transaccionesNodo = formatNumberWithCommas(
        statisticsByDate?.peticionesTransacciones
          ?.peticiones_validacion_identidad ?? 0
      );

      objetoProvisional.texts = {
        titulo: {
          text: "Información dashboard",
          x: convertWidth(29),
          y: convertHeight(75),
          font: {
            fontName: "Mulish",
            fontStyle: "normal",
            fontWeight: 700,
            size: 15,
            textColor: "#22135E",
            lineHeight: 18.83,
          },
        },
        titulo2: {
          text: "/ " + (nameGeneral ?? "Consorcio"),
          x: convertWidth(200),
          y: convertHeight(75),
          font: {
            fontName: "Mulish",
            fontStyle: "normal",
            fontWeight: 700,
            size: 15,
            textColor: "#bcbcbc",
            lineHeight: 18.83,
          },
        },
        fechas: {
          text: `Desde: ${dayjs(
            `${values.start_date} ${
              values.start_hours !== "" ? values.start_hours : "00:00"
            }`
          ).format("DD/MM/YYYY HH:mm")} hrs | Hasta: ${dayjs(
            `${values.end_date} ${
              values.end_hours !== "" ? values.end_hours : "00:00"
            }`
          ).format("DD/MM/YYYY HH:mm")} hrs`,
          x: convertWidth(397),
          y: convertHeight(69),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 6,
            textColor: "#2D2D2DCC",
            lineHeight: 10.04,
          },
        },
        fechaConsulta: {
          text: `Fecha y hora del reporte ${dayjs().format(
            "DD/MM/YYYY HH:mm:ss"
          )} (GMT-6)`,
          x: convertWidth(412),
          y: convertHeight(84),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 6,
            textColor: "#2D2D2DCC",
            lineHeight: 10.04,
          },
        },
        valorRegistrosNodos: {
          text: `${statisticsKPI?.estatus_nodos?.activos.toString() ?? 0}/${
            statisticsKPI?.estatus_nodos?.total.toString() ?? 0
          }`,
          x: 12.1633333333 * 3 * widthConversionFactor,
          y: 44.26 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        registrosNodos: {
          text: `Nodos en el consorcio`,
          x: 5.85666666667 * 3 * widthConversionFactor,
          y: 47.6666666667 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorIndiceCalidadRegistro: {
          text: formatToTwoDecimals(statisticsKPI?.calidadRegistroPromedio).toString() ?? "0",
          x: 12.1633333333 * 3 * widthConversionFactor,
          y: 61.6666667 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        indiceCalidadRegistro: {
          text: `Promedio índice de calidad del registro`,
          x: 5.85666666667 * 3 * widthConversionFactor,
          y: 65.3333333 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorRegistrosConsorcio: {
          text: statisticsKPI?.noRegistros.toString() ?? "0",
          x: 59.7933333 * 3 * widthConversionFactor,
          y: 44.26 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        transaccionesNodo: {
          text: `Registros en el consorcio`,
          x: 53.5233333 * 3 * widthConversionFactor,
          y: 47.6666666667 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorIndiceCalidadFoto: {
          text: formatToTwoDecimals(statisticsKPI?.calidadFotoPromedioValidacion).toString() ?? "0",
          x: 59.7933333 * 3 * widthConversionFactor,
          y: 61.6666667 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        indiceCalidadFoto: {
          text: `Promedio índice calidad de la fotografía`,
          x: 53.5233333 * 3 * widthConversionFactor,
          y: 65.3333333 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorNumeroCargasNodo: {
          text:
            statisticsKPI?.peticionesTransacciones?.peticiones_validacion_identidad.toString() ??
            "0",
          x: 108.61 * 3 * widthConversionFactor,
          y: 44.26 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        numeroCargasNodo: {
          text: `Transacciones en el consorcio`,
          x: 102.676667 * 3 * widthConversionFactor,
          y: 47.6666666667 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorNumeroCargasConsorcio: {
          text: statisticsKPI?.no_Cargas.toString() ?? "0",
          x: 108.61 * 3 * widthConversionFactor,
          y: 61.6666667 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        numeroCargasConsorcio: {
          text: `Cargas en el consorcio`,
          x: 102.676667 * 3 * widthConversionFactor,
          y: 65.3333333 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        valorPromedioIndiceRespuesta: {
          text: statisticsKPI?.tiempoPromedio.toString() ?? "0",
          x: 157.39 * 3 * widthConversionFactor,
          y: 61.6666667 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 19.08,
            textColor: "#22135E",
            lineHeight: 23.94,
          },
        },
        valorPromedioIndiceRespuestaTiming: {
          text: "seg",
          x: 182.666667 * 3 * widthConversionFactor,
          y: 61.6666667 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "normal",
            size: 10.02,
            textColor: "#22135E",
            lineHeight: 12.57,
          },
        },
        promedioIndiceRespuesta: {
          text: `Promedio tiempo de respuesta`,
          x: 150.343333 * 3 * widthConversionFactor,
          y: 65.3333333 * 3 * heightConversionFactor,
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 5.3,
            textColor: "#959595",
            lineHeight: 6.65,
          },
        },
        etiquetaTransacciones: {
          text: `Total de transacciones:`,
          x: convertWidth(18.06),
          y: convertHeight(233),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#22135E",
            lineHeight: 10.98,
          },
        },
        etiquetaTotalTransaccionesNodo: {
          text: `Transacciones por nodo:`,
          x: convertWidth(356.41),
          y: convertHeight(233),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#2D2D2DCC",
            lineHeight: 10.98,
          },
        },
        valorTotalTransacciones: {
          text: transaccionesNodo.toString(),
          x: convertWidth(120),
          y: convertHeight(233),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#2D2D2D",
            lineHeight: 10.98,
          },
        },
        etiquetaTiempoRespuesta: {
          text: `Promedio tiempo de respuesta del nodo:`,
          x: convertWidth(17.53),
          y: convertHeight(413),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#22135E",
            lineHeight: 10.98,
          },
        },
        valorTiempoRespuesta: {
          text: `${statisticsByDate?.tiempoPromedio ?? "0"} seg`,
          x: convertWidth(191),
          y: convertHeight(413),
          font: {
            fontName: "Mulish",
            fontStyle: "bold",
            size: 8.75,
            textColor: "#2D2D2D",
            lineHeight: 10.98,
          },
        },
      };
      objetoProvisional.rects = {
        rect1: {
          x: 2.527,
          y: 42.728,
          w: 48.831,
          h: 16.356,
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect2: {
          x: 54.852,
          y: 42.728,
          w: 49.051,
          h: 16.356,
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect3: {
          x: 106.198,
          y: 42.728,
          w: 48.831,
          h: 16.356,
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect4: {
          x: 2.527,
          y: 63.688,
          w: 48.831,
          h: 16.356,
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect5: {
          x: 54.852,
          y: 63.688,
          w: 49.051,
          h: 16.356,
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect6: {
          x: 106.198,
          y: 63.688,
          w: 48.831,
          h: 16.356,
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect7: {
          x: 158.2,
          y: 63.688,
          w: 49.051,
          h: 16.356,
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect9: {
          x: convertWidth(7),
          y: convertHeight(211),
          w: convertWidth(332.43),
          h: convertHeight(173),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect10: {
          x: convertWidth(345.34),
          y: convertHeight(211),
          w: convertWidth(227.03),
          h: convertHeight(173),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
        rect11: {
          x: convertWidth(7),
          y: convertHeight(391),
          w: convertWidth(565),
          h: convertHeight(151.58),
          styles: {
            fillColor: "#FFFFFF",
            strokeColor: "#efeded",
          },
        },
      };

      objetoProvisional.images = {
        image1: {
          image: "/images/reportes/pdf/logo_alliance.png",
          format: "",
          x: convertWidth(30),
          y: convertHeight(29),
          width: convertWidth(225.34),
          height: convertHeight(18),
        },
        image2: {
          image: "/images/icons/nodes.png",
          format: "",
          x: 6.31 * 3 * widthConversionFactor,
          y: 40.31 * 3 * heightConversionFactor,
          width: 5.19333333333 * 3 * widthConversionFactor,
          height: 3.63 * 3 * heightConversionFactor,
        },
        image3: {
          image: "/images/dashboard/dashboard_db.png",
          format: "",
          x: 53.9933333 * 3 * widthConversionFactor,
          y: 39.36666 * 3 * heightConversionFactor,
          width: 4.39 * 3 * widthConversionFactor,
          height: 5.05 * 3 * heightConversionFactor,
        },
        image4: {
          image: "/images/dashboard/dashboard_transactions.png",
          format: "",
          x: 102.25 * 3 * widthConversionFactor,
          y: 40.7766667 * 3 * heightConversionFactor,
          width: 5.19333333333 * 3 * widthConversionFactor,
          height: 3.64666666667 * 3 * heightConversionFactor,
        },
        image5: {
          image: "/images/icons/edit.png",
          format: "",
          x: convertWidth(17.57),
          y: convertHeight(171.21),
          width: convertWidth(15.58),
          height: convertHeight(14),
        },
        image6: {
          image: "/images/icons/image.png",
          format: "",
          x: 53.9933333 * 3 * widthConversionFactor,
          y: 57 * 3 * heightConversionFactor,
          width: convertWidth(15.58),
          height: convertHeight(15),
        },
        image7: {
          image: "/images/dashboard/dashboard_cargas.png",
          format: "",
          x: 102.25 * 3 * widthConversionFactor,
          y: 56.59666667 * 3 * heightConversionFactor,
          width: 5.00666667 * 3 * widthConversionFactor,
          height: 5.08666667 * 3 * heightConversionFactor,
        },
        image8: {
          image: "/images/icons/clock.png",
          format: "",
          x: 151.456667 * 3 * widthConversionFactor,
          y: 56.88 * 3 * heightConversionFactor,
          width: 5.19333333 * 3 * widthConversionFactor,
          height: 5.19333333 * 3 * heightConversionFactor,
        },
        image9: {
          image: await handleCapture(
            availableCharts.TransactionsByNodeChart,
            captureRefs,
            fetchFallbackImage
          ),
          format: "",
          x: convertWidth(356.41),
          y: convertHeight(255.62),
          width: getPieChartPDF("width"),
          height: getPieChartPDF("height"),
        },
        image10: {
          image: await handleCapture(
            availableCharts.TransactionsConsorcioChart,
            captureRefs,
            fetchFallbackImage
          ),
          format: "",
          x: convertWidth(10.32),
          y: convertHeight(252.36),
          width: convertWidth(318),
          height: convertHeight(110),
        },
        image11: {
          image: await handleCapture(
            availableCharts.promedio_tiempo_respuesta_nodo,
            captureRefs,
            fetchFallbackImage
          ),
          format: "",
          x: convertWidth(10.32),
          y: convertHeight(431.4),
          width: convertWidth(529),
          height: convertHeight(101),
        },
      };
      objetoProps.content.push(objetoProvisional);
      objetoProps.numberPages = objetoProps.numberPages + 1;
      objetoProps.getObjectDocument = getObjectDocument;
      objetoProps.getError = getError;
      setPropsPDF(objetoProps);
    } catch (error) {
      console.warn(error);
    } finally {
      // setLoadingExport(false);
    }
  };

  const validateResolution = () => {
    let width = window.innerWidth;
    if (width >= 1600) setPadding(105);
    else if (width >= 1400) setPadding(65);
    else {
      setPadding(5);
    }
  };

  return (
    <div
      id="statistics_consorcio_container"
      className="statistics_consorcio_container p-0"
    >
      <StatisticsConsorcio statisticsConsorcioData={statisticsKPI} />
      <div
        className="d-flex flex-row align-items-center dco_gracicasDashboard-filtros-busqueda flex-wrap-reverse justify-content-end flex-lg-nowrap justify-content-lg-end my-3 px-3"
        style={{ flex: 2 }}
      >
        {Permisos.verificarPermiso("Dashboard", "Buscar") && (
          <FilterDashboard
            {...{
              handleDatePickerChange,
              handleChange,
              values,
            }}
          />
        )}
        <div className="my-1 mt-3 mx-1 justify-content-end d-flex">
          <div style={{ marginRight: "10px" }}>
            <LimpiarFiltros
              handleChange={handleChange}
              INITIAL_VALUES={get_INITIAL_VALUES()}
              modulo="Dashboard"
            />
          </div>
          {Permisos.verificarPermiso("Dashboard", "Exportar") && (
            <ModalExportDashboard
              exportarGraficasImagen={exportarGraficasImagen}
              setLoading={setLoadingExport}
            />
          )}
        </div>
      </div>
      <div className="charts_container px-3 py-4">
        <div className="duo_charts_container gap-3">
          <div style={{ display: "flex", flex: 1.5 }}>
            <TransactionsConsorcioChart
              widthGeneral="100%"
              heightGeneral="fit-content"
              size={{
                height: 219,
              }}
              padding={{ right: padding }}
              flexFlow=""
              resumenNumero={
                statisticsByDate?.peticionesTransacciones
                  ?.peticiones_validacion_identidad ?? 0
              }
              data={dataCharts?.peticiones?.Historicos ?? {}}
              refKey="TransactionsConsorcioChart"
              setRef={(key, el) => setRef(key, el, captureRefs)}
            />
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <TransactionsByNodeChart
              data={transactionsByNode?.Proporciones ?? []}
              nodesCatalog={nodesCatalog ?? []}
              widthGeneral="100%"
              heightGeneral="auto"
              flexFlow=""
              size={{
                height: 219,
              }}
              refKey="TransactionsByNodeChart"
              setRef={(key, el) => setRef(key, el, captureRefs)}
            />
          </div>
        </div>
        <div className="mt-4">
          <TimeAverageConsorcioChart
            widthGeneral="100%"
            heightGeneral="fit-content"
            size={{
              height: 219,
            }}
            padding={{ right: padding }}
            flexFlow="1 0 600px"
            titulo="Promedio tiempo de respuesta del nodo: "
            resumenNumero={`${statisticsByDate?.tiempoPromedio ?? 0} seg`}
            data={dataCharts?.tiempoPromedio?.Historicos ?? []}
            setRef={(key, el) => setRef(key, el, captureRefs)}
            refKey="promedio_tiempo_respuesta_nodo"
          />
        </div>
      </div>
      {showAlert && (
        <AlertaGeneral type={typeAlert}>{alertMessage}</AlertaGeneral>
      )}
      {(loading || loadingExport) && <Loader />}
      {Object.keys(propsPDF).length > 0 && <ReportePDF {...propsPDF} />}
    </div>
  );
};

export default ConsorcioStatisticsData;
